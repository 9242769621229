import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "modal"
    }}>{`Modal`}</h1>
    <p>{`JavaScript modal plugin to add dialogs to your site for lightboxes, user notifications, or completely custom content.`}</p>
    <div className="box">
  <strong>Attention</strong>: the use of the components needs the{" "}
  <span className="pink">squid.js</span> included in the project
    </div>
    <div className="box">
  <strong>Attention + 1</strong>: Probably the interaction via Javascript should
  not work well in SPA applications (independent of the framework React,
  Angular, Vuejs, etc). For now we have no implementation but we will create in
  the future, for now just use the styles and create your own implementation
    </div>
    <button type="button" className="button button-pink mb-3" data-modal="modal-exemple">
  Open
    </button>
    <div className="modal align-items-center" id="modal-exemple" data-backdrop="static">
  <div className="modal-dialog modal-md">
    <div className="modal-content">
      <div className="modal-header">
        <h6 className="m-0">Modal Title</h6>
        <button className="button-close modal-exit">
          <i className="far fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <p>Content Modal</p>
      </div>
      <div className="modal-footer">
        <button className="button button-white black mr-3 modal-exit">Close</button>
        <button className="button button-pink">Button</button>
      </div>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button type="button" data-modal="modal-id">
  <!-- Button content, data-modal must reference the \`id\` attribute of the modal -->
</button>
<div class="modal" id="modal-id" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <!-- Modal Title -->
        <button class="button-close modal-exit">
          <i class="far fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <!-- Modal Content -->
      </div>
      <div class="modal-footer">
        <!-- Modal Footer -->
      </div>
    </div>
  </div>
</div>
`}</code></pre>
    <h2 {...{
      "id": "modal-backdrop-close"
    }}>{`Modal backdrop Close`}</h2>
    <p>{`To close modal when the backdrop is clicked, just remove the `}<inlineCode parentName="p">{`data-backdrop="static"`}</inlineCode>{` attribute to the modal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="modal" id="modal-id">
  <!-- Content -->
</div>
`}</code></pre>
    <h2 {...{
      "id": "modal-sizes"
    }}>{`Modal Sizes`}</h2>
    <p>{`Just add one of the classes below to `}<inlineCode parentName="p">{`.modal-dialog`}</inlineCode>{`.
If no size class is passed, the modal will be the full size of the screen.
Where value is one of:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.modal-sm,
.modal-md,
.modal-lg,
.modal-xl
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      